export function pluginDnaEventHandler(
  event,
  document_button_id,
  document_error_id
) {
  var jsonstr = JSON.stringify(event.detail, null, 2);
  let dnaEvent = event.detail[0];
  if (
    dnaEvent.type === 'documentFound' ||
    dnaEvent.type === 'documentCreated'
  ) {
    let docData;
    if (dnaEvent.type === 'documentFound') {
      docData = dnaEvent.payload[0];
    } else if (dnaEvent.type === 'documentCreated') {
      docData = dnaEvent.payload;
    }
    if (docData !== undefined) {
      updateDomAfterGetDocument(event, docData, document_button_id);
    }
  } else {
    console.log('ERROR: ' + jsonstr);
    hiddePluginButton(document_button_id);
    showUploadPluginButton('upload-' + document_button_id);
    showUploadPluginButton('get-doc-upload-' + document_button_id);
    showPluginButtonError(
      document_error_id,
      'DNA : ' + JSON.parse(jsonstr)[0]['type']
    );
  }
}

function updateDomAfterGetDocument(event, docData, document_button_id) {
  download_document_via_wso2(docData, document_button_id);
}

function download_document_via_wso2(docData, document_button_id) {
  var dna_document_url = document.getElementById(
    'ds_minarm_api_dna_document_url'
  ).value;
  var data_champ_id = document_button_id.split('_')[1];

  if (docData !== undefined && docData.documentId && docData.user) {
    let data = {
      document_id: docData.documentId,
      administre_id: docData.user.uid,
      data_champ_id: data_champ_id
    };
    let queryString = Object.keys(data)
      .map((key) => key + '=' + data[key])
      .join('&');
    let download_endpoint = dna_document_url + '?' + queryString;

    fetch(download_endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then((response) => response.json())
      .then((result) => {
        hiddePluginButton(document_button_id);
        if (result !== undefined) {
          showUploaderResponse(result, data_champ_id);
        }
      })
      .catch(function (error) {
        console.log(
          "Il y a eu un problème avec l'opération fetch: " + error.message
        );
      });
  }
}

function hiddePluginButton(document_button_id) {
  let domNode = document.getElementById(document_button_id);
  domNode.hidden = true;
}

function showUploadPluginButton(document_button_id) {
  let domNode = document.getElementById(document_button_id);
  domNode.hidden = false;
}

function showPluginButtonError(document_error_id, error) {
  let domErrorNode = document.getElementById(document_error_id);
  domErrorNode.innerHTML = error;
}

function showUploaderResponse(result, data_champ_id) {
  let champ_selector = ".editable-champ[data-champ-id='" + data_champ_id + "']";
  document.querySelector(champ_selector).outerHTML = result['content'];
}
