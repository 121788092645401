import $ from 'jquery';
import 'moment';
import 'daterangepicker';

('use·strict');

$(document).ready(function () {
  // Initialise les date pickers sur la page
  initDateRangePickers();

  var target = document.getElementById('bloc-repetition');

  if (target) {
    // Mise en place d'un MutationObserver pour tracker les ajouts dans le DOM
    // On observe seulement la proriété childList, nous ne surveillons pas le reste.
    // En d'autres termes, on surveille l'ajout de blocs `Periode`.
    var properties = {
      childList: true
    };

    observer.observe(target, properties);
  }
});

var observer = new MutationObserver(function (mutationRecords) {
  mutationRecords.forEach(function () {
    // Chaque mutation observée et capturée plus haut re-instancie les date pickers.
    initDateRangePickers();
  });
});

// Ajoute un datepicker sur tous les champs de type `daterangepicker`
function initDateRangePickers() {
  $('[data-behavior=daterangepicker]').daterangepicker({
    locale: {
      format: 'DD/MM/YYYY',
      separator: ' - ',
      applyLabel: 'Valider',
      cancelLabel: 'Annuler',
      fromLabel: 'De',
      toLabel: 'à',
      customRangeLabel: 'Custom',
      daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      monthNames: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre'
      ],
      firstDay: 1
    },
    cancelLabel: 'Clear'
  });

  $('[data-behavior=daterangepicker]').on(
    'cancel.daterangepicker',
    function () {
      $(this).val(' ');
    }
  );
}
