export function updateFieldValue(
  element,
  url,
  params,
  value,
  api_dataset_keys,
  api_dataset_editable,
  is_public
) {
  var HttpClient = function () {
    this.get = function (aUrl, aCallback) {
      var anHttpRequest = new XMLHttpRequest();
      anHttpRequest.onreadystatechange = function () {
        if (anHttpRequest.readyState == 4 && anHttpRequest.status == 200)
          aCallback(anHttpRequest.responseText);
      };
      anHttpRequest.open('GET', aUrl, true);
      anHttpRequest.send(null);
    };
  };

  if (element.value.replace(/ /g, '').length != 0) {
    var xhr = new HttpClient();
    var full_url = url + btoa(element.value);
    xhr.get(full_url, function (response) {
      var obj = JSON.parse(response);
      var dataset_keys = api_dataset_keys.split('|');
      var dataset_editable = api_dataset_editable.split('|');
      var item_index = 0;

      var analyseTargetName = element.name.toString().split('][');
      let inRepetable, indexReel;
      if (analyseTargetName.length == 5) {
        inRepetable = analyseTargetName[1];
        indexReel = analyseTargetName[3];
      } else {
        inRepetable = -1;
        indexReel = analyseTargetName[1];
      }

      let dossierAttributes;
      if (is_public == 'true') {
        dossierAttributes = 'dossier_champs_attributes_';
      } else {
        dossierAttributes = 'dossier_champs_private_attributes_';
      }

      dataset_keys.forEach(function (dataset_key) {
        let dataset_element_id;
        if (inRepetable > -1) {
          dataset_element_id =
            dossierAttributes +
            inRepetable +
            '_champs_attributes_' +
            indexReel +
            '_champs_attributes_' +
            item_index +
            '_value';
        } else {
          dataset_element_id =
            dossierAttributes +
            indexReel +
            '_champs_attributes_' +
            item_index +
            '_value';
        }

        var currentvalue = '';
        if (response != '{}') {
          currentvalue = obj[dataset_key];
        }

        if (dataset_key == 'unite') {
          document.getElementById(dataset_element_id).value =
            currentvalue.toUpperCase();
        } else {
          document.getElementById(dataset_element_id).value = currentvalue;
        }
        if (currentvalue != '') {
          if (dataset_editable[item_index] == 'false') {
            document
              .getElementById(dataset_element_id)
              .setAttribute('readonly', 'readonly');
          }
        }
        item_index = item_index + 1;
      });
    });
  }
}

export function updateFieldValueByResponse(
  event,
  response,
  api_dataset_keys,
  api_dataset_editable,
  is_public
) {
  if (event.target.value.replace(/ /g, '').length != 0) {
    var parser = new DOMParser();
    var dom = parser.parseFromString(
      '<!doctype html><body>' + response,
      'text/html'
    );
    var decodedresponse = dom.body.textContent;
    var obj = JSON.parse(decodedresponse);
    var dataset_keys = api_dataset_keys.split('|');
    var dataset_editable = api_dataset_editable.split('|');
    var item_index = 0;

    var analyseTargetName = event.target.name.toString().split('][');
    let inRepetable, indexReel;
    if (analyseTargetName.length == 5) {
      inRepetable = analyseTargetName[1];
      indexReel = analyseTargetName[3];
    } else {
      inRepetable = -1;
      indexReel = analyseTargetName[1];
    }

    let dossierAttributes;
    if (is_public == 'true') {
      dossierAttributes = 'dossier_champs_attributes_';
    } else {
      dossierAttributes = 'dossier_champs_private_attributes_';
    }

    dataset_keys.forEach(function (dataset_key) {
      let dataset_element_id;
      if (inRepetable > -1) {
        dataset_element_id =
          dossierAttributes +
          inRepetable +
          '_champs_attributes_' +
          indexReel +
          '_champs_attributes_' +
          item_index +
          '_value';
      } else {
        dataset_element_id =
          dossierAttributes +
          indexReel +
          '_champs_attributes_' +
          item_index +
          '_value';
      }

      var currentvalue = '';
      if (response != '{}') {
        currentvalue = obj[dataset_key];
      }

      if (dataset_key == 'unite') {
        document.getElementById(dataset_element_id).value =
          currentvalue.toUpperCase();
      } else {
        document.getElementById(dataset_element_id).value = currentvalue;
      }
      if (currentvalue != '') {
        if (dataset_editable[item_index] == 'false') {
          document
            .getElementById(dataset_element_id)
            .setAttribute('readonly', 'readonly');
        }
      }
      item_index = item_index + 1;
    });
  }
}
