import $ from 'jquery';

export function disable_sub_champ(id_div) {
  `${$('#' + id_div + ' *').prop('disabled', true)}`;
}

export function manage_sub_champ(event, element, id_div) {
  `${$('#' + id_div + ' *').prop('disabled', true)}`;
  var checkbox = document.getElementById(element.id);
  var div_compose = document.getElementById(id_div);
  if (checkbox.checked) {
    `${$('#' + id_div + ' *').prop('disabled', false)}`;
    div_compose.style.display = 'block';
  } else {
    div_compose.style.display = 'none';
    `${$('#' + id_div + ' *').prop('disabled', true)}`;
  }
}
