import $ from 'jquery';

export function changeTypeAvisListe(
  event,
  listeTypeAvis,
  listeNiveauxInstruction,
  indexAvis,
  indexNiveauDeuxInstruction
) {
  var indexCourantNiveau = listeNiveauxInstruction.indexOf(event.target.value);
  var selectTypeAvis = document.getElementById('procedure_avis_type_id');

  for (var i in selectTypeAvis.options) {
    if ('undefined' !== typeof selectTypeAvis.options[i].text) {
      selectTypeAvis.options[i].text = listeTypeAvis[indexCourantNiveau][i];
      selectTypeAvis.options[i].value = listeTypeAvis[indexCourantNiveau][i];
    }
  }
  var niveauValidation = document.getElementById(
    'procedure_niveau_validation'
  ).parentElement;
  if (
    selectTypeAvis.value === listeTypeAvis[0][indexAvis] ||
    selectTypeAvis.value === listeTypeAvis[1][indexAvis]
  ) {
    niveauValidation.style.display = 'block';
  } else {
    niveauValidation.style.display = 'none';
  }

  //activation ou désactivation de la liste des choix pour la variable "Demarche déclarative"
  var niveauInstructionSelected = document.getElementById(
    'procedure_niveau_instruction'
  ).value;
  var demarcheDeclarativeList = document.getElementById(
    'procedure_declarative_with_state'
  );

  if (
    niveauInstructionSelected ===
    listeNiveauxInstruction[indexNiveauDeuxInstruction]
  ) {
    //demarcheDeclarativeList.style.display = 'none';
    `${$(demarcheDeclarativeList).prop('disabled', true)}`;
  } else {
    //demarcheDeclarativeList.style.display = 'block';
    `${$(demarcheDeclarativeList).prop('disabled', false)}`;
  }
}

export function changeNiveauValidationListe(
  event,
  listeTypeAvis,
  indexAvis,
  niveauValidationMax
) {
  var isAvis =
    listeTypeAvis[0][indexAvis] === event.target.value ||
    listeTypeAvis[1][indexAvis] === event.target.value;
  var niveauValidation = document.getElementById(
    'procedure_niveau_validation'
  ).parentElement;
  var libelleNiveauValidation = document.getElementById(
    'libelle_niveau_validation'
  );
  var firstLibelleNiveauValidation = document.getElementById(
    'libelle_niveau_validation_0'
  );

  if (isAvis) {
    niveauValidation.style.display = 'block';
    libelleNiveauValidation.style.display = 'block';
    firstLibelleNiveauValidation.style.display = 'block';
  } else {
    niveauValidation.style.display = 'none';
    libelleNiveauValidation.style.display = 'none';
    firstLibelleNiveauValidation.style.display = 'none';

    for (var j = 0; j < niveauValidationMax; j++) {
      document.getElementById('libelle_niveau_validation_' + j).style.display =
        'none';
      document.getElementById(
        'procedure_libelle_niveau_validation_niveau_validation_' + j
      ).required = false;
    }
  }
}

export function changeLibelleNiveauValidationListe(event, niveauValidationMax) {
  var libelleNiveauValidationSize = event.target.value;
  if (libelleNiveauValidationSize > 0) {
    document.getElementById('libelle_niveau_validation').style.display =
      'block';

    for (var i = 0; i < libelleNiveauValidationSize; i++) {
      document.getElementById('libelle_niveau_validation_' + i).style.display =
        'block';
      document.getElementById(
        'procedure_libelle_niveau_validation_niveau_validation_' + i
      ).required = true;
    }
  }

  for (var j = libelleNiveauValidationSize; j < niveauValidationMax; j++) {
    document.getElementById('libelle_niveau_validation_' + j).style.display =
      'none';
    document.getElementById(
      'procedure_libelle_niveau_validation_niveau_validation_' + j
    ).required = false;
  }
}

export function autoRoutageEnabledChange() {
  var auto_routage_enabled = document.getElementById(
    'procedure_auto_routage_enabled'
  );

  auto_routage_enabled_change(auto_routage_enabled.checked);
  get_types_demarche(auto_routage_enabled.checked);
}

export function auto_routage_enabled_change(checked) {
  if (checked) {
    document.getElementById('procedure_auto_routage_enabled').value = '1';
    document.getElementById('edit_champ_routage_enabled').style.display =
      'block';
  }
  if (!checked) {
    document.getElementById('procedure_auto_routage_enabled').value = '0';
    document.getElementById('procedure_auto_routage_enabled').checked = false;
    document.getElementById('edit_champ_routage_enabled').style.display =
      'none';
    document.getElementById('procedure_edit_champ_routage_enabled').value = '0';
    document.getElementById(
      'procedure_edit_champ_routage_enabled'
    ).checked = false;
  }
}

export function editChampRoutageEnabled() {
  var edit_champ_routage_enabled = document.getElementById(
    'procedure_edit_champ_routage_enabled'
  );

  if (edit_champ_routage_enabled.checked) {
    document.getElementById('procedure_edit_champ_routage_enabled').value = '1';
  } else {
    document.getElementById('procedure_edit_champ_routage_enabled').value = '0';
  }
}

export function get_types_demarche(auto_routage_enabled) {
  var url =
    document.getElementById('types_demarche_url').value +
    '?auto_routage=' +
    auto_routage_enabled;

  $.ajax(url, {
    method: 'get',
    contentType: 'application/json',
    dataType: 'json',
    success: function (data) {
      if (typeof data == 'undefined' || data.length == 0) {
        console.log('GET TYPES_DEMARCHE response is Empty! : ' + data);
        var error_message =
          "Aucun type de démarche n'est défini dans le DNA. Vous ne pouvez pas choisir le routage de l'instruction.";
        document.getElementById('flash_messages').innerHTML = '';
        document.getElementById('flash_messages').innerHTML =
          '<div class="center" id="flash_message"><div class="alert alert-danger">' +
          error_message +
          '<br></div></div>';
        auto_routage_enabled_change(false);
      } else {
        var ds_label = 'Démarches Simplifiées';
        var label = auto_routage_enabled == true ? 'DNA' : ds_label;
        var response_html = '';
        response_html += '<option value="" label=" "></option>';
        response_html += '<optgroup label="' + label + '">';
        for (var i = 0; i < data.length; i++) {
          response_html +=
            '<option value="' +
            JSON.stringify(data[i]).replace(/["]/g, '&quot;') +
            '">' +
            data[i]['type_demarche'] +
            '</option>';
        }
        response_html += '</optgroup>';
        document.getElementById('procedure_procedure_type').innerHTML = '';
        document.getElementById('procedure_procedure_type').innerHTML =
          response_html;
      }
    },
    error: function (error) {
      console.log('Error: ' + error);
    }
  });
}

export function procedureSynchroChange(event, niveauValidationMax) {
  var procedure_synchro = event.target.value;
  var procedure_avis_type = document.getElementById('procedure_avis_type_id');
  var procedure_niveau_validation = document.getElementById(
    'procedure_niveau_validation'
  );

  auto_routage_enabled_change(false);
  get_types_demarche(false);
  procedure_niveau_validation.value = '1';
  procedure_niveau_validation.dispatchEvent(new Event('change'));
  procedure_avis_type.value = "Pas d'avis";
  procedure_avis_type.dispatchEvent(new Event('change'));

  if (procedure_synchro == 'true') {
    document.getElementById('avis_type').style.display = 'none';
    document.getElementById('auto_routage_enabled').style.display = 'none';

    for (var j = 0; j < niveauValidationMax; j++) {
      document.getElementById('libelle_niveau_validation_' + j).style.display =
        'none';
      document.getElementById(
        'procedure_libelle_niveau_validation_niveau_validation_' + j
      ).required = false;
    }
  }

  if (procedure_synchro == 'false') {
    document.getElementById('avis_type').style.display = 'block';
    document.getElementById('auto_routage_enabled').style.display = 'block';
  }
}

export function disabledBySynchro() {
  $('.synchro-disabled:not(:last-child) :input').attr('disabled', true);
}
