import '../shared/polyfills';
import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import 'whatwg-fetch'; // window.fetch polyfill
import ReactRailsUJS from 'react_ujs';

import '../shared/page-update-event';
import '../shared/activestorage/ujs';
import '../shared/remote-poller';
import '../shared/safari-11-file-xhr-workaround';
import '../shared/remote-input';
import '../shared/franceconnect';
import '../shared/toggle-target';
import '../shared/ujs-error-handling';

import '../new_design/chartkick';
import '../new_design/dropdown';
import '../new_design/form-validation';
import '../new_design/procedure-context';
import '../new_design/procedure-form';
import '../new_design/spinner';
import '../new_design/support';
import '../new_design/messagerie';
import '../new_design/dossiers/auto-save';
import '../new_design/dossiers/auto-upload';
import { pluginDnaEventHandler } from '../new_design/dossiers/dna_plugin_handler';

import '../new_design/champs/carte';
import '../new_design/champs/linked-drop-down-list';
import '../new_design/champs/repetition';
import '../new_design/champs/drop-down-list';
import '../new_design/champs/periode';
import {
  updateFieldValue,
  updateFieldValueByResponse
} from '../new_design/champs/apis';
import {
  disable_sub_champ,
  manage_sub_champ
} from '../new_design/champs/Check_condition_champ';
import {
  changeTypeAvisListe,
  changeNiveauValidationListe,
  changeLibelleNiveauValidationListe,
  autoRoutageEnabledChange,
  editChampRoutageEnabled,
  disabledBySynchro,
  procedureSynchroChange
} from '../new_design/administrateur';

import {
  toggleCondidentielExplanation,
  replaceSemicolonByComma
} from '../new_design/avis';
import {
  showMotivation,
  showMotivationAvis,
  motivationCancel,
  showImportJustificatif
} from '../new_design/state-button';
import {
  acceptEmailSuggestion,
  discardEmailSuggestionBox
} from '../new_design/user-sign_up';
import {
  showFusion,
  showNewAccount,
  showNewAccountPasswordConfirmation
} from '../new_design/fc-fusion';

// This is the global application namespace where we expose helpers used from rails views
const DS = {
  fire: (eventName, data) => Rails.fire(document, eventName, data),
  toggleCondidentielExplanation,
  showMotivation,
  showMotivationAvis,
  motivationCancel,
  showImportJustificatif,
  showFusion,
  showNewAccount,
  showNewAccountPasswordConfirmation,
  replaceSemicolonByComma,
  acceptEmailSuggestion,
  discardEmailSuggestionBox,
  disable_sub_champ,
  manage_sub_champ,
  changeTypeAvisListe,
  updateFieldValue,
  updateFieldValueByResponse,
  changeNiveauValidationListe,
  changeLibelleNiveauValidationListe,
  autoRoutageEnabledChange,
  editChampRoutageEnabled,
  disabledBySynchro,
  procedureSynchroChange,
  pluginDnaEventHandler
};

// Start Rails helpers
Rails.start();
ActiveStorage.start();

// Expose globals
window.DS = window.DS || DS;

// eslint-disable-next-line no-undef, react-hooks/rules-of-hooks
ReactRailsUJS.useContext(require.context('loaders', true));
addEventListener('ds:page:update', ReactRailsUJS.handleMount);
